const ImprintAreaDialog4i = function () {
	let init = () => {			
		const viewImprintDialog = new Dialog4i();
		const triggers = [].slice.call(document.querySelectorAll(".viewImprintAreaLink"));
		const dialogContent = '<div id="imprintLoading" class="spinner" role="alert" aria-busy="true" aria-atomic="true" aria-label="Loading imprint area, please wait"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>';
		
		viewImprintDialog.Init({
			triggerElements: triggers,
			dialogClass: "viewImprintAreaDialog largeDialog", 
			title: "Loading View Imprint Area", 
			content: dialogContent, 
			onOpen: open
		});
	},
	getViewImprintClass = () => {
		let flyoutImg = document.getElementById("popUpDialog").querySelector('.viewImprintAreaImg');
		
		let boxWidth, boxHeight;
		flyoutImg.onload = () => {
		    	boxWidth = flyoutImg.naturalWidth;
			boxHeight = flyoutImg.naturalHeight;

			//add in the actual height/width of the image to the image tag
			flyoutImg.height = boxHeight;
			flyoutImg.width = boxWidth;
			document.querySelector(".largeDialog").style.width = (boxWidth + 30) + "px";
		};
	},
	open = () => {
		updateHTML();
		getViewImprintClass();
		
		/*if (document.querySelector(".itemInfoBtn")) {
       	 	let addlInfoBtns = [].slice.call(document.querySelectorAll(".itemInfoBtn"));
	            	addlInfoBtns.forEach(function(el) {
	                	el.addEventListener("click", toggleImprintInfo);
	            });
		}*/
	},
	updateHTML = () => { 
		let mockupURL = document.getElementById("mockupURL").value;
		let productName = document.querySelector(".prodName").textContent;
		let productNumber = document.querySelector(".prodNumber").textContent;
			
		let imprintInfo = document.getElementById("mockupImpAreaInfo").innerHTML;
		
		let htmlContent = 
			'<div class="posRelative">' +
				'<div class="displayFlex flexSpaceBetween">' +
					'<div class="marginBtm10">' +
						'<h2 class="textSemiBold text18 marginBtm5">' + productName + '</h2>' +
						'<p class="text16">' + productNumber + '</p>' +
					'</div>' +
					'<div class="viewImprintProdInfo bkgdLtBlue roundCorners5 marginBtm10">' + imprintInfo + '</div>' +
				'</div>' +
				'<img class="displayFlex responsiveImg flyoutImg viewImprintAreaImg" id="viewImprintAreaImg" src="' + mockupURL + '" alt="Imprint Area of ' + productName + '" height="600" width="600" />' + 
			'</div>';
		
		document.getElementById("popUpDialog").querySelector("h2").textContent =  "Imprint Area";
		document.getElementById("popUpDialog").querySelector(".dialogContent").innerHTML = htmlContent;
	};
	/*toggleImprintInfo = e => {
		e.preventDefault();
		let el = e.currentTarget;
		if (el.parentNode.querySelector(".viewImprintProdInfo").classList.contains("hide")) {
            		el.parentNode.querySelector(".viewImprintProdInfo").classList.remove("hide");
            		el.querySelector(".arrowIcon").classList.remove("rotate180");
            		el.setAttribute("aria-expanded", true);
    	  	} else {
            		el.parentNode.querySelector(".viewImprintProdInfo").classList.add("hide");
            		el.querySelector(".arrowIcon").classList.add("rotate180");
            		el.setAttribute("aria-expanded", false);
    	  	}
	};*/
	return {
		Init: init
	};
};	

(function(){
	const ImprintAreaDialog = new ImprintAreaDialog4i();
	ImprintAreaDialog.Init();
})();